import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo"

class Letter extends React.Component {
    render () {
        return <h3>{this.props.letter}</h3>
    }
}

class Pages extends React.Component {
  render () {
    var newUrl = this.props.url
    var website = newUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
    return <p className="inline">
          <a href={this.props.url} target="_blank" rel="noopener noreferrer">{website}{this.props.index === this.props.arr.length - 1 ? `` : `,\u00A0`}</a>
    </p>
  }
}

class Peoples extends React.Component {
    render () {
        return   <p className="inline">
                    {this.props.email ? (
                        <span><a href={"mailto:" + this.props.email}>{this.props.name}</a>{this.props.task ? `,\u00A0` : `\u00A0`? this.props.page ? `,\u00A0`: `\u00A0` : `\u00A0`}</span>
                    ) : (
                        <span>{this.props.name}{this.props.task ? `,\u00A0` : `\u00A0` ? this.props.page ? `,\u00A0`: `\u00A0` : `\u00A0`}</span>
                    )}
                    {this.props.task ? (
                        <span>{this.props.task}{this.props.page ? (`,\u00A0`) : (`\u00A0`)}</span>
                    ) : (
                        ``
                    )}
                </p>
    }
}

const LeuteTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    {data.wordpressPage.acf.add_letter.map((letter) => (
       <div className="letters">
           <Letter letter={letter.letter}> </Letter>
               {letter.add_person.map((person) => (
                   <div className="peoples">
                       <Peoples key={person.uniqueId} name={person.name} email={person.email} task={person.task} page={person.add_pages}>

                       </Peoples>
                       {person.add_pages ? (
                          person.add_pages.map((url, index, arr) => (
                            <Pages url={url.url} index={index} arr={arr} key={url.uniqueId}></Pages>
                          )) 
                       ) : (
                         ``
                       )}
                      </div>
               ))}        
       </div>
    ))}
  </Layout>
)
export default LeuteTemplate

export const query = graphql`
  query {
    wordpressPage(slug: {eq: "leute"}) {
        title
        excerpt
        slug
        acf {
          add_letter {
            add_person {
              email
              task
              name
              add_pages {
                url
              }
            }
            letter
          }
        }
      }
  }
`